import React from "react";
import {
  Box,
  Container,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import "./../styles.css";
import pic1 from "./../../assets/customLogo.png";
import pic2 from "./../../assets/login.svg";
import Grid from "@mui/material/Grid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useState } from "react";
import { base_url1 } from "../../constants";
import Axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { OTPInputField } from "react-otp-input-type";
import LinearProgress from "@mui/material/LinearProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  loginDetails,
  currentUserDetails,
} from "../../redux/actions/LoanActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import md5 from 'js-md5';
import { Redirect, useLocation } from "react-router-dom";
import Footer from "./../Common/footer";
import { resetPasswordChange } from "./../apiCalls/api";
import Alert from "@mui/material/Alert";
export const theme = createTheme({
  typography: {
    // fontFamily: "Times New Roman",
  },
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function Login() {
  const [error, setError] = React.useState(false);
  const [OTPerror, setOTPerror] = React.useState(false);
  const [resetPasswordFlag, setresetPasswordFlag] = React.useState(false);
  const [seconds, setSeconds] = useState(120);
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [message, setmessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [encryptedPassword, setEncryptedPassword] = React.useState("");
  const [isValidPass, setIsValidPass] = React.useState(false);
  const [invalidOtpCount, setInvalidOtpCount] = React.useState(0);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState("");
  const [captcha, setCaptcha] = useState(null);
  const [isCaptchaError, setCaptchaError] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  // const location = useLocation();

  // const setRedirect = () => {
  //   setRedirectToReferrer(true);
  // };
  // const { from } = location.state || { from: { pathname: "/dashboard" } };

  // if (redirectToReferrer) {
  //   history.push(from);
  // }

  const initiateSession = () => {
    Axios.get(`${base_url1}/digitalLoans/initiateSession`)
      .then((result) => {
        if (result.status === 200) {
          setCaptcha(result.data?.data?.captcha);
          setSessionId(result.data?.data?.session_id);
          setCaptchaError(false);
        }
      })
      .catch((error) => {
        setCaptchaError(true);
      });
  };

  useEffect(() => {
    initiateSession();
  }, []);

  const hash_256 = (value) => {
    return CryptoJS.SHA256(value).toString(CryptoJS.enc.Hex);
  };

  const hash_md5 = (value) => {
    return md5(value);
  };

  const currentUser = useSelector((state) => state.currentUserDetails);
  useEffect(() => {
    let interval = null;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    user_captcha: "",
  });
  const [resetPasswordFields, setresetPasswordFields] = useState({
    username: "",
    oldPassword: "",
    password: "",
    confirmPassword: "",
    user_captcha:"",
  });
  const [otp, setOtp] = useState("");
  const resetPassword = () => {
    initiateSession();
    setresetPasswordFlag(!resetPasswordFlag);
    setCredentials({ ...credentials, username: "", password: "" });
    setresetPasswordFields({
      ...resetPasswordFields,
      username: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });
    setmessage("");
    setIsValid(false);
    setEmailError("");
  };
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  const handleOTPChange = (val) => {
    setOtp(val);
  };
  const checkBackend = async () => {
    let bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    let config = {
      withCredentials: true,
    };
    const response2 = fetch(`${base_url1}/test_authenticate_user`, { headers });
    setisAPILoading(false);
    sessionStorage.setItem("Email", credentials.username);
  };
  const sendDataToBackend = async () => {
    setisAPILoading(true);
    try {
      const response = await Axios.post(
        `${base_url1}/login`,
        {
          email: credentials.username,
          user_password: encryptedPassword,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        sessionStorage.setItem("Access_Token", response.data.access_token);
        setOTPerror(false);
        setInvalidOtpCount(0);
        console.log(invalidOtpCount);
        setisAPILoading(false);
        dispatch(currentUserDetails(response.data.data));
        checkBackend();
        if (response.data.data.role_access == "checker") {
          dispatch(loginDetails(0));
          sessionStorage.setItem("Role", 0);
        } else {
          dispatch(loginDetails(1));
          sessionStorage.setItem("Role", 1);
        }
        if (response.data.data.role_name == "CMS - Admin User") {
          navigate("/settings");
        }
        if (response.data.data.role_name == "sales_ops") {
          navigate("/notificationServices");
        }
        if (
          response.data.data.role_name != "external_agency" &&
          response.data.data.role_name != "lawyer" &&
          response.data.data.role_name != "CMS - Admin User" &&
          response.data.data.role_name != "branch_manager" &&
          response.data.data.role_name != "sales_ops"
        ) {
          window.location.href = "./dashboard";
        }
        if (
          response.data.data.role_name == "external_agency" ||
          response.data.data.role_name == "lawyer" ||
          response.data.data.role_name == "branch_manager"
        ) {
          window.location.href = "./details";
        }
      } else {
        setOTPerror(true);
        setInvalidOtpCount(prevCount => prevCount + 1);
        initiateSession();
        if(invalidOtpCount >= 2){
          setOtpErrorMessage("Too many incorrect OTP tries. Resend OTP.");
        }
        else{
          setOtpErrorMessage("Invalid OTP.")
        }
      }
    } catch (error) {
      setOTPerror(true);
      setInvalidOtpCount(prevCount => prevCount + 1);
      initiateSession();
      if(invalidOtpCount >= 2){
        setOtpErrorMessage("Too many incorrect OTP tries. Resend OTP.");
      }
      else{
        setOtpErrorMessage("Invalid OTP.")
      }
      console.error("Error sending data to the backend:", error);
    }
  };

  const verifyOTP = () => {
    setOTPerror(false);
    sendDataToBackend();
    // Axios.post(`${base_url1}/validate_cms_user_otp`, {
    //   user_id: credentials.username,
    //   otp: otp,
    // }).then((result) => {
    //   if (result.data.status != "success") {
    //     setOTPerror(true);
    //   } else {
    //     setOTPerror(false);
    //     navigate("/dashboard");
    //   }
    // });
  };
  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInputChangePassword = (e) => {
    setresetPasswordFields({
      ...resetPasswordFields,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "username") {
      validateEmail(e.target.value);
    }
    if (e.target.name == "password") {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
      const isPasswordValid = passwordRegex.test(e.target.value);
      const passwordContainsUsername = resetPasswordFields.password.includes(
        resetPasswordFields.username
      );
      setIsValidPass(!isPasswordValid || passwordContainsUsername);
    }
    if (e.target.name == "confirmPassword") {
      if (e.target.value == resetPasswordFields.password) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  };
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };
  const changePassword = async () => {
    setisAPILoading(true);
    if (emailError || isValid || isValidPass) {
      console.log("Error");
      setisAPILoading(false);
      initiateSession();
    } else {
      try{
        const results = await resetPasswordChange(resetPasswordFields,sessionId);
        console.log(results.data.msg);
        if (results.data.msg != "Password Updated") {
          setmessage(results.data.msg);
          setisAPILoading(false);
          initiateSession();
        } else {
          resetPassword();
          initiateSession();
          setisAPILoading(false);
        }
      }catch(error){
          initiateSession();
          setmessage(error.message);
          setisAPILoading(false);
      }
    }
  };
  const handleClose = () => {
    initiateSession();
    setOpen1(false);
    setCredentials({ ...credentials, username: "", password: "" });
  };


  const beforevalidateUsers = () => {
    setError(false);
    setisAPILoading(true);
    Axios.get(`${base_url1}/getSalt?user_id=${credentials?.username}`).then((result) => {
      if (result) 
      {
        const encryptPassword = hash_md5(hash_256(credentials?.password) + result?.data?.response_data);
        setEncryptedPassword(encryptPassword);
        setError(false);
        validateUsers(encryptPassword);
      }else {
        setError(true);
        setisAPILoading(false);
        initiateSession();
      }
    })
    .catch((error) => {
      initiateSession();
      console.error("Error during user validation:", error);
      setError(true);
      setisAPILoading(false);
    });
  };

  const validateUsers = (encryptPassword) => {
    setError(false);
    setOTPerror(false);
    setOtp("");
    setInvalidOtpCount(0);
    setisAPILoading(true);
    // Axios.get(`${base_url1}/test_maker_role`).then((result) => {
    //   if (result !== []) {
    //   } else {
    //     // if there is no data in the database
    //   }
    // });
    Axios.post(`${base_url1}/validate_cms_user`, {
      user_id: credentials.username,
      password: encryptPassword,
      session_id: sessionId,
      user_captcha: credentials.user_captcha,
    }).then((result) => {
      if (result.data.data.login_result != 1 || result.response_data != null) 
      {
        setErrorMessage(result?.data?.message);
        setError(true);
        setisAPILoading(false);
        initiateSession();
      }
      else if(result?.data?.data?.has_logged_in == 0 || result?.data?.data?.is_expired == 1){
        setresetPasswordFlag(true);
        setisAPILoading(false);
        initiateSession();
      }
       else {
        setError(false);
        setisAPILoading(false);
        handleClickOpen();
        // window.location.href = "./dashboard";
      }
    })
    .catch((error) => {
      console.error("Error during user validation:", error);
      setErrorMessage(error?.response?.data?.response_message);
      setError(true);
      initiateSession()
      setisAPILoading(false);
    });
  };
  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      {isAPILoading ? (
        <Grid item xs={12} style={{ marginTop: "0%" }}>
          <LinearProgress
            sx={{
              color: "#3f48cc",
            }}
            size={40}
            thickness={4}
          />
        </Grid>
      ) : null}
      <img src={pic1} style={{ margin: "1%", height: "7%", width: "20%" }} />

      <Container
        maxWidth
        align="center"
        style={{
          background: "linear-gradient(90deg, #fafafa 50%, #fff 50%)",
          height: "80vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          textAlign="center"
          style={{
            width: "61rem",
            height: "110%",
            boxShadow: "0 4px 12px rgb(0 0 0 / 15%)",
            borderRadius: "10px",
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
              <Grid item xs={12} sm={6} md={6}>
                <img
                  src={pic2}
                  style={{
                    margin: "1%",
                    height: "100%",
                    width: "100%",
                    paddingTop: "20%",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ marginTop: "5%" }}>
                <Box style={{ margin: "3%" }}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "800",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        color: "#3f48cc",
                      }}
                    >
                      Gosree Finance
                    </span>{" "}
                    Case Management System{" "}
                  </Typography>
                </Box>
                <Box style={{ margin: "3%" }}>
                  <Typography variant="h6"> Login </Typography>
                </Box>
                <Box style={{ margin: "5%" }}>
                  <Divider />
                </Box>
                {!resetPasswordFlag ? (
                  <div>
                    {error ? (
                      <Box className="errorMessage" style={{ margin: "5%" }}>
                        {errorMessage}
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box style={{ margin: "5%" }}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        name="username"
                        value={credentials.username}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Box>
                    <Box style={{ margin: "5%" }}>
                      <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        variant="outlined"
                        name="password"
                        value={credentials.password}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center", margin: "5%" }}>
                      <Grid container spacing={2} style={{ width: "100%" }}>
                          <Grid item xs={6} sm={6} style={{ display: "flex", alignItems: "center" }}>
                              <img
                                  src={`data:image/png;base64,${captcha}`}
                                  alt="Image"
                                  style={{
                                      height: "7vh",
                                      width: "90%",
                                      marginLeft: "10%",
                                  }}
                              />
                              <Button onClick={initiateSession}>
                                  <ReplayIcon style={{ color: "#3f48cc" }} />
                              </Button>
                          </Grid>
                          <Grid item xs={6} sm={6} style={{ display: "flex", alignItems: "center" }}>
                              <TextField
                                  label="Enter Captcha"
                                  type="text"
                                  className="custom-text-field"
                                  name="user_captcha"
                                  style={{
                                      height: "auto",
                                      width: "80%",
                                      marginBottom: "1rem",
                                      marginLeft: "3rem",
                                  }}
                                  onChange={(e) =>
                                    handleInputChange(e)
                                  }
                              />
                          </Grid>
                      </Grid>
                    </Box>
                    {/* <Box style={{ cursor: "pointer", margin: "5%" }}>
                  <Typography align="left" variant="body2" color="textPrimary">
                    Forgot password
                  </Typography>
                </Box> */}
                    <Box
                      textAlign="right"
                      style={{ margin: "5%" }}
                      sx={{
                        color: "#3f48cc !important",
                        fontWeight: "800 !important",
                      }}
                    >
                      <Typography variant="body2">
                        {" "}
                        <Link onClick={(e) => resetPassword()}>
                          Reset Password
                        </Link>{" "}
                      </Typography>
                    </Box>
                    <Box textAlign="center" style={{ margin: "5%" }}>
                      <Button
                        size="large"
                        variant="contained"
                        style={{ background: "#3f48cc", color: "#ffffff" }}
                        onClick={beforevalidateUsers}
                      >
                        <Link className="atag">Login</Link>
                      </Button>
                      <BootstrapDialog
                        className="optCSS"
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open1}
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={handleClose}
                          style={{ fontWeight: "800", color: "#3f48cc" }}
                        >
                          LOGIN
                        </BootstrapDialogTitle>

                        <DialogContent>
                          {OTPerror ? (
                            <Box
                              className="errorMessage"
                              style={{ margin: "5%" }}
                            >
                              {otpErrorMessage}
                            </Box>
                          ) : (
                            ""
                          )}
                          <Grid item xs={12} sm={6} md={6} mt={2}>
                            <Typography
                              sx={{ m: 4, flex: 1 }}
                              component="div"
                              style={{
                                maxWidth: "100% !important",
                                textAlign: "center",
                              }}
                            >
                              We have sent you <b>One Time Password</b> to your
                              email.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} mt={2}>
                            <Typography
                              sx={{ m: 4, flex: 1 }}
                              component="div"
                              style={{
                                maxWidth: "100% !important",
                                textAlign: "center",
                                color: "#3f48cc",
                              }}
                            >
                              Please Enter OTP
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={12} sm={6} md={6} mt={2}>
                        <Typography
                          sx={{ m: 4, flex: 1 }}
                          component="div"
                          style={{
                            maxWidth: "100% !important",
                            textAlign: "center",
                            fontWeight: "800",
                          }}
                        >
                          {`${minutes}:${
                            remainingSeconds < 10 ? "0" : ""
                          }${remainingSeconds}`}
                        </Typography>
                      </Grid> */}
                          <Grid item xs={12} sm={6} md={6} mt={2}>
                            <OTPInputField
                              className="otp"
                              numOfInputs={6}
                              handleChange={handleOTPChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} mt={2}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              mt={2}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className="buttonText"
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  textTransform: "capitalize",
                                  color: "#3f48cc",
                                  fontWeight: "800",
                                }}
                                className="buttonText"
                                onClick={beforevalidateUsers}
                              >
                                Resend OTP
                              </Button>
                              {invalidOtpCount >= 3 ? (
                                <Button
                                variant="Contained"
                                sx={{
                                  textTransform: "capitalize",
                                  fontWeight: "800",
                                }}
                                className="buttonText1"
                                onClick={verifyOTP}
                                disabled 
                                >
                                  Verify OTP
                                </Button>
                              ) : (
                                <Button
                                variant="contained"
                                sx={{
                                  textTransform: "capitalize",
                                  fontWeight: "800",
                                }}
                                className="buttonText1"
                                onClick={verifyOTP}
                              >
                                Verify OTP
                              </Button>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} mt={2}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              mt={4}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  textTransform: "capitalize",
                                  color: "#3f48cc",
                                  fontWeight: "800",
                                }}
                                onClick={handleClose}
                              >
                                Login with another account
                              </Button>
                            </Grid>
                          </Grid>
                        </DialogContent>
                        {/* <DialogActions>
                        <Button
                          autoFocus
                          className="download-button"
                          onClick={handleClose}
                        >
                          Close
                        </Button>
                      </DialogActions> */}
                      </BootstrapDialog> 
                    </Box>
                  </div>
                ) : (
                  <div>
                    <div style={{ height: "48.5vh", overflow: "auto" }}>
                      {message != "" ? (
                        <Box style={{ marginLeft: "5%", textAlign: "center" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              ml: 5,
                              ml: 0,
                              justifyContent: "center",
                            }}
                          >
                            <Alert severity="error">{message}</Alert>
                          </Grid>
                        </Box>
                      ) : null}
                      <Box style={{ margin: "5%" }}>
                        <TextField
                          fullWidth
                          label="Email"
                          variant="outlined"
                          name="username"
                          value={resetPasswordFields.username}
                          onChange={(e) => handleInputChangePassword(e)}
                          error={!!emailError}
                          helperText={emailError}
                        />
                      </Box>
                      <Box style={{ margin: "5%" }}>
                        <TextField
                          fullWidth
                          label="Old Password"
                          type="password"
                          variant="outlined"
                          name="oldPassword"
                          value={resetPasswordFields.oldPassword}
                          onChange={(e) => handleInputChangePassword(e)}
                        />
                      </Box>
                      <Box style={{ margin: "5%" }}>
                        <TextField
                          fullWidth
                          label="New Password"
                          type="password"
                          variant="outlined"
                          name="password"
                          error={isValidPass}
                          value={resetPasswordFields.password}
                          onChange={(e) => handleInputChangePassword(e)}
                          helperText={
                            isValidPass
                              ? "Password must be at least 8 characters long and contain at least one letter and one number."
                              : null
                          }
                        />
                      </Box>
                      <Box style={{ margin: "5%" }}>
                        <TextField
                          fullWidth
                          label="Confirm Password"
                          type="password"
                          variant="outlined"
                          name="confirmPassword"
                          value={resetPasswordFields.confirmPassword}
                          onChange={(e) => handleInputChangePassword(e)}
                          error={isValid}
                          helperText={isValid ? "Password Doesnt Match" : null}
                        />
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center", margin: "5%" }}>
                      <Grid container spacing={2} style={{ width: "100%" }}>
                          <Grid item xs={6} sm={6} style={{ display: "flex", alignItems: "center" }}>
                              <img
                                  src={`data:image/png;base64,${captcha}`}
                                  alt="Image"
                                  style={{
                                      height: "7vh",
                                      width: "90%",
                                      marginLeft: "10%",
                                  }}
                              />
                              <Button onClick={initiateSession}>
                                  <ReplayIcon style={{ color: "#3f48cc" }} />
                              </Button>
                          </Grid>
                          <Grid item xs={6} sm={6} style={{ display: "flex", alignItems: "center" }}>
                              <TextField
                                  label="Enter Captcha"
                                  type="text"
                                  className="custom-text-field"
                                  name="user_captcha"
                                  style={{
                                      height: "auto",
                                      width: "80%",
                                      marginBottom: "1rem",
                                      marginLeft: "3rem",
                                  }}
                                  onChange={(e) =>
                                    handleInputChangePassword(e)
                                  }
                              />
                          </Grid>
                      </Grid>
                  </Box>
                    </div>
                    {/* <Box style={{ cursor: "pointer", margin: "5%" }}>
                  <Typography align="left" variant="body2" color="textPrimary">
                    Forgot password
                  </Typography>
                </Box> */}
                    <Box
                      textAlign="right"
                      style={{ marginRight: "5%" }}
                      sx={{
                        color: "#3f48cc !important",
                        fontWeight: "800 !important",
                      }}
                    >
                      <Typography variant="body2">
                        {" "}
                        <Link onClick={(e) => resetPassword()}>
                          Login Here
                        </Link>{" "}
                      </Typography>
                    </Box>
                    <Box textAlign="center" style={{ margin: "5%" }}>
                      <Button
                        size="large"
                        variant="contained"
                        style={{ background: "#3f48cc", color: "#ffffff" }}
                        onClick={changePassword}
                      >
                        <Link className="atag">Reset Password</Link>
                      </Button>
                    </Box>
                  </div>
                )}
                {/* <Box textAlign="center" style={{ margin: "5%" }}>
                  <Typography variant="body2">
                    {" "}
                    Don't have an account ? <Link>Register here</Link>{" "}
                  </Typography>
                </Box> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
